import React from "react";
import SEO from "@components/seo";
import Layout from "@components/layout";
import Footer from "@components/footers/dark";
import MenuBar from "@components/menuBar/MenuBar";
import FeatureList from "@components/feature-list";
import Hero from "./Hero.js";
import Cta from "@components/Cta";
import Qube from "@components/qube/qube.js";

import GiftingSolutions from "./features/GiftingSolutions.js";
import PhysicalGiftCards from "./features/PhysicalGiftCards.js";
import MyReceiptRewards from "./features/MyReceiptRewards.js";
import DigitalRetailerStore from "./features/DigitalRetailerCards.js";
import CardApp from "./features/CardApp";
import Solutions from "./features/Solutions.js";
import ScrollingLogos from "./features/LogoCarousel.js";
import DigitalCard from "./features/DigitalCardAndDigitalApp.js";

export default function Corporate() {
  return (
    <>
      <SEO title="Team" />
      <MenuBar/>
      <Layout>
      <Hero/>
      <Solutions/>
      <CardApp/>
      <ScrollingLogos/>
      <FeatureList alternate>
        <GiftingSolutions/>
        <DigitalCard/>
        <PhysicalGiftCards/>
        <DigitalRetailerStore/>
        <MyReceiptRewards/>
      </FeatureList>
      <Qube/>
      <Cta>
        <span>
          WAIVPAY&rsquo;s digital, physical and cashback payments technology
          for instant rewarding, recognition and incentives.
        </span>
      </Cta>
      </Layout>
      <Footer/>
    </>
  );
}
