import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ImageTag from "gatsby-image";

const Qube = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(name: { eq: "shopping-centre-interior" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <section id="qube" className="relative bg-gray-900 ">
      <div className="h-80 absolute bottom-0 xl:inset-0 xl:h-full xl:w-full overflow-hidden">
        <div className="h-full w-full xl:grid xl:grid-cols-2">
          <div className="h-full xl:relative xl:col-start-2">
            <ImageTag Tag="div"
              fluid={image.sharp.fluid}
              imgStyle={{ filter: "grayscale(100%)" }}
              className="h-full w-full opacity-25 xl:absolute xl:inset-0"
              alt="Interior upper-level concourse in a large, modern shopping centre"
            />
            <div aria-hidden="true" className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"></div>
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
        <div className="relative pt-12 pb-12 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
          <h2 className="flex justify-between text-sm font-semibold tracking-wide uppercase">
            <span className="text-highlight">
              WAIVPAY Qube
            </span>
          </h2>
          <p className="mt-3 text-3xl font-extrabold text-white">Actionable reporting to increase engagement and shopper traffic</p>
          <p className="mt-5 text-lg text-gray-300">
            Gain deep understanding of the effectiveness of your campaigns and customer engagement with WAIVPAY Qube.
            Discover, interrupt and communicate meaningful patterns of shoppers&rsquo; and customers&rsquo; behaviours.
            WAIVPAY Qube delivers effective reporting, decision making and invaluable forecasting to get the most value
            of your shopping centre assets and customers&rsquo; wallets.
          </p>
          <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
            <p>
              <span className="block text-2xl font-bold text-white">Reporting</span>
              <span className="mt-1 block text-base text-gray-300">
                <span className="font-medium text-white">Fast, real-time, accurate reporting</span> of your gift-card and cashback campaigns.
              </span>
            </p>

            <p>
              <span className="block text-2xl font-bold text-white">Analytics</span>
              <span className="mt-1 block text-base text-gray-300">
                <span className="font-medium text-white">Analyse shopper behaviour</span> to deeply understand engagement and campaign effectiveness.
              </span>
            </p>

            <p>
              <span className="block text-2xl font-bold text-white">Forecasting</span>
              <span className="mt-1 block text-base text-gray-300">
                <span className="font-medium text-white">Create predictions</span> for scenarios that add value to your brand and shopping centre assets.
              </span>
            </p>

            <p>
              <span className="block text-2xl font-bold text-white">Decision Making</span>
              <span className="mt-1 block text-base text-gray-300">
                <span className="font-medium text-white">Create strategic initiatives</span> with actionable, competitive, executive information.
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Qube;
