import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Fixed } from "@components/feature-list";
import StartButton from "@components/StartButton";

const EftposCards = ({ getStarted, reverse }) => {
  const images = useStaticQuery(graphql`
    query {
      xs: file(name: { eq: "digital-card-corporate" }) {
        sharp: childImageSharp {
          fixed(width: 438) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      sm: file(name: { eq: "digital-card-corporate" }) {
        sharp: childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      md: file(name: { eq: "digital-card-corporate" }) {
        sharp: childImageSharp {
          fixed(width: 560) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const sources = [
    images.xs.sharp.fixed,
    {
      ...images.sm.sharp.fixed,
      media: `(min-width: 640px AND max-width: 767px)`,
    },
    {
      ...images.md.sharp.fixed,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Fixed
      id="physical-eftpos"
      description={<Description getStarted={!!getStarted}/>}
      image={sources}
      reverse={!reverse} />
  );
}

const Description = ({ getStarted }) => (
  <div>
    <div>
      <h2>
        <span className="block text-base text-highlight font-semibold tracking-wide uppercase">
          GIFTING | LOYALTY
        </span>

        <span className="block text-3xl font-bold tracking-tight text-gray-900 mt-1">
          Digital Gift Card
        </span>
      </h2>
      <p className="mt-4 text-lg text-gray-500">
        Your brand is powerful, and the best way to incentivise and reward is by providing your own 
        branded App and digital card. Yes, have your card in the Apple or Google digital wallet. 
        Rewards to your staff or clients, promotions; what better showcase your brand than having 
        it in a digital wallet?
      </p>
      <p className="mt-4 text-lg text-gray-500">
        Beautifully designed with your chosen artwork, WAIVPAY's mastercards increase brand awareness,
        cements loyalty and increases sales. Each card can store value from $1 to $1,000.
      </p>

      <p className="mt-4 text-lg text-gray-500">
        Pair with WAIVPAY Qube for deep insights into customer behaviours and traffic analytics.
      </p>
      {
        getStarted &&
          <div className="mt-6">
            <StartButton to="/giftcards" />
          </div>
      }
    </div>
  </div>
);

export default EftposCards;
